import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {connect} from 'react-redux';
import FsLightbox from 'fslightbox-react';
import $ from 'jquery';
import {
	ARTICLE_VIEWER,
	ARTICLE_LIST,
	GLOBALS,
	HIGHLIGHTS_KEY,
	ARTICLE_ARTICLEVIEW,
	NO_LOGIN,
	FOOTNOTE_KEY,
	DEFAULT_ARTICLE_ZOOM_LEVEL
} from "../../_MODULE_GLOBALS/constants";
import {ID_NOT_SET} from "../../_MODULE_GLOBALS/constants";
import {fetchData, updateData} from '../../../store/actions';
import GenerateTitle from "../../../widgets/generateTitle";
import {
	generateMops,
	generateQueryParams,
	displayOnDevice,
	dataModuleAttributes,
	getDocType
} from "../../../utils/moduleSetup";
import * as Tracking from "../../../utils/tracking";
import {GenerateInProgressPage, manageDeviceResize} from "../../../widgets/generatePages";
import {GeneratedArticleContent} from "./widgets/generateArticleContent";
import {manageUpdateAndNavigation} from "../../globals/globalNavigator";
import { useVisibilityChange } from "../../../hooks/useVisibilityChange";
import { useKeyEvents, enableDisableKeyEvent } from "../../../hooks/useKeyEvents";
import {setTemplateClass, triggerOnArticle} from "../../../triggers/triggerOnArticle";
import {useFetchAttributesChange, useFetchComplete} from "../../../hooks/useFetchChange";
import {getFloatValue, getIntValue, isEmpty, isTrue, valuesEqual} from "../../../utils/generalUtils";
import {useAttributesChanged} from "../../../hooks/useAttributesChanged";
import {populateFetchDataAttributes} from "../../../utils/populateFetchDataAttributes";
import {addClass} from "../../../utils/generateClassName";
import {reorderNavigationKeysModules} from "../../../utils/reorderNavigationKeysModules";
import {getArticleList} from "../../../utils/articleList/getArticleList";
import {getStoreValue} from "../../../utils/storeValue";
import {getDocTypeAttribute} from "../../../utils/getDocTypeAttribute";
import {getObjectFromJSON, clone} from "../../../utils/objectUtils";
import {webHighlighter, displayHighlights, retrieveHighlights} from "../../../utils/webHighlighter";
import {updateArticleDataTables} from "./post_render/updateArticleDataTables";
import {PreviousNextButton} from "../../../widgets/generatePreviousNextButton";
import {getPreviousNextIds, previousNextClicked} from "../../../utils/previousNextUtils";
import {deviceArticleLayout, findArticle, openArticle} from "../../../utils/articleList/articleActions";
import {useMountPostRender} from "../../../hooks/useMount";
import {useScrolledIntoView} from "../../../hooks/useScrolledIntoView";
import {getModuleContainer} from "../../globals/globalLibraryData";
import {getChildNodes, getNodeFromClass} from "../../../utils/htmlUtils";
import {Translate} from "../../../locales/locales";
import {updateShareUrl} from "../../globals/shareUrl";



// Maximum tracking duration
const MAX_DURATION = 30 * 60; // 30 minutes (in seconds)

// list of possible query params sent with api call
// key is props name; value is fetch parameter
const configQueryParams = {
//	'articleIds':'articleIds',  // not currently used; re-enable if decide we need this
//	'maxEntries':'maxEntries',  // maxEntries has been removed
	'pageSize':'pageSize',  // maxEntries was an alias for pageSize
	'categories':'categories',
	'excludeContentTypes':'excludeContentTypes',
	'issueUrl': 'issueUrl',
	'authorNames': 'authorNames',
	'sortBy':'sortBy',
	'publicationIds':'publicationIds',
	'recentIssues':'recentIssues',
	'pageNumber':'pageNumber',
	'isLoggedIn': 'isLoggedIn',
	'userAccessHash': 'userAccessHash',
	'isPurchaseSuccessful': 'isPurchaseSuccessful',
	'purchaseProductId' : 'purchaseProductId',
	'withContent': {attribute: "withContent", default: true},  // we always want content for the viewer
	'u1': 'u1',

};


/*
	list of potential configuration parameters; other than query params
		className: optional class name to add to div
		displayTitle: {true/false} - display the title; default false if not set
		i18nId: {string} - key to string for title in strings (locale) file
		displayThumbnail: {true/false} - show/hide article thumbnail
		displayIssue: {true/false} - show/hide issue name
		displaySummary: {true/false} - show/hide article summary
		displayCategory: {true/false} - show/hide category name
		maxDisplay: {value} - number of entries to display for paging
 */

/*
// NOTE: not currently used; for portal additions
const GenerateAdditionalArticleElements = (params) => {
	params = Object.assign({
		props: {},
		articleAttributes: {}
	}, params);
//	const moduleDOMElement = !isEmpty(params.articleAttributes.moduleDOMElement) ? params.articleAttributes.moduleDOMElement : null;
	const DOMElement = !isEmpty(params.articleAttributes.DOMElement) ? params.articleAttributes.DOMElement : null;
	const DOMElementReady = isTrue(params.articleAttributes.DOMElementReady, {defaultValue: false}) ? params.articleAttributes.DOMElementReady : false;
	const allParams = Object.assign({}, params,{
//		moduleDOMElement: moduleDOMElement,
		DOMElement: DOMElement,
		DOMElementReady: DOMElementReady
	});

	if (DOMElementReady) {
		console.log("CALLED GENERATE");
		return <FractionalAd {...allParams} />;
	} else {
		console.log("SKIPPING GENERATION");
		return null;
	}
};
*/


/**
 * Provide a consistent way to track ad viewed and ad clicked as well as clicking on
 * 3rd party links embedded within article content.
 *
 * @param params
 *     articleId: current article id
 *     articleList: current article list
 *     element: clicked/viewed element
 *     category: link/ad viewed/clicked
 *     additionalAttributes: any additional attributes to send for tracking
 */
const trackAdsAndLinks = (params) => {
	params = Object.assign({
		articleId: '',
		articleList: [],
		element: null,
		category: "advertisement viewed",
		additionalAttributes: {},
		issueGroup: '',
	}, params);

	const currentArticle = findArticle({articleList: params.articleList, articleId: params.articleId});
	const category = !isEmpty(params.category) ? params.category : 'advertisement viewed';
	const title = !isEmpty(currentArticle.title) ? currentArticle.title : '';
	const issueName = !isEmpty(currentArticle.issueName) ? currentArticle.issueName : '';
	const trackingAttribute = !isEmpty(params.element.getAttribute('data-tracking-id')) ? params.element.getAttribute('data-tracking-id') : title;

	const trackingProperties = Object.assign({
		"category": category,
		"location": "article",
		"module": 'articleViewer',
		"issue name": "_" + issueName,
		"issue date": new Date(currentArticle.issuePublicationDate).toISOString().slice(0, 10),
		"issue group": params.issueGroup,
		"name": trackingAttribute,
		"article title": title,
		"article title - issue name": title + " - " + issueName,

	}, params.additionalAttributes);

	Tracking.libraryTrack(category, trackingProperties);
};


/**
 * Capture click on advertisement or link (a tags) and make a tracking call.
 * Note: setup is called each time an article is changed to ensure that we
 * have the current article for click capture.
 *
 * @param params article objects
 *     articleList: list of articles
 *     articleId: current article id
 */
const setupLinkTracking = (params) => {
	params = Object.assign({
		articleList: [],
		issueGroup: '',
	}, params);
	const $app = $("#App");
	if (isEmpty(params.articleList)) {
		$app.off("click.article-ads").off("click.article-links");
	} else {
		// capture click on fractional ad ('advertisement' tag) in article and track
		$app.off("click.article-ads").on("click.article-ads", ".articleContent advertisement", function(event) {
			const $target = $(event.target);

			// don't track if click on fullscreenIcon
			if (!$target.parents('a').hasClass('fullscreenIcon')) {
				trackAdsAndLinks({
					category: "advertisement clicked",
					issueGroup: params.issueGroup,
					articleId: getIntValue($target.parents('[data-article-id]').attr('data-article-id'), 0),  // make sure it is an integer
					articleList: params.articleList,
					element: event.currentTarget,
					additionalAttributes: {
						url: $target.parents('a').attr('href'),
					}
				});
			}
		});

		// capture click on full page advertisement article and track
		$app.off("click.full-page-ads").on("click.full-page-ads", ".articleContent .fullPageAd a.full-page-ad-link", function(event) {
			const $target = $(event.target);

			trackAdsAndLinks ({
				category: "advertisement clicked",
				issueGroup: params.issueGroup,
				articleId: getIntValue($target.parents('[data-article-id]').attr('data-article-id'), 0),  // make sure it is an integer
				articleList: params.articleList,
				element: event.currentTarget,
				additionalAttributes: {
					url: $target.parents('a').attr('href'),
				}
			});
		});

		// capture click on links (a tag) in article and track
		// filter out (:not) the following link types
		//    .full-page-ad-links
		//    .lightboxPictureWrap
		//    .lightboxPageWrap
		//    .fullscreenIcon
		$app.off("click.article-links").on("click.article-links", ".articleContent a:not(.full-page-ad-link,.lightboxPictureWrap,.lightboxPageWrap,.replicaThumbnailWrap,.fullscreenIcon)", function(event) {
			const $target = $(event.target);
			const href = $target.closest('a').attr('href');

			// only track if has href and not part of <advertisement> (tracked elsewhere as "advertisement clicked")
			if (typeof href === 'string' && $target.parents('advertisement').length === 0) {
				// matches http:// or https:// or // or / or none at start; then looks for domain after
				const matchDomain = new RegExp('(https*://|//|/|.*?)(.*?)(/|$)');
				trackAdsAndLinks ({
					category: "link clicked",
					issueGroup: params.issueGroup,
					articleId: getIntValue($target.parents('[data-article-id]').attr('data-article-id'), 0),  // make sure it is an integer
					articleList: params.articleList,
					element: event.currentTarget,
					additionalAttributes: {
						type: 'article content',
						domain: href.match(matchDomain)[2],
						url: href,
						label: $target.text().trim(),
					}
				});
			}
		});
	}
};


/**
 * Call tracking with article and time, as long as the stored articleId is not ID_NOT_SET
 *
 * @param params tracking parameters
 */
const trackArticleView = (params) => {
	params = Object.assign({
		trackArticle: {},
		trackingStartTime: 0,
		trackingEndTime: 0,
		trackingEndType: '',
		authenticationType: '',
		isLoggedIn: false,
		issueGroup: '',
	}, params);
	if (isEmpty(params.trackArticle)) {
		return;
	}
	const trackArticle = Object.assign({
		title: '',
		issueName: '',
		categories: [],
		customTemplate: 'default',
		articleId: ID_NOT_SET,
		issuePublicationDate: 0
	}, params.trackArticle);

	// make sure that stored articleId not ID_NOT_SET which indicates first-time in articleViewer
	if (trackArticle.articleId === ID_NOT_SET) {
		return;
	}

	// duration (in seconds), but no more than MAX_DURATION
	let duration = (params.trackingEndTime - params.trackingStartTime)/1000;
	if (duration > MAX_DURATION) {
		duration = MAX_DURATION;
	}
	const trackingProperties = {
		"type": "article view",
		"module": "articleViewer",
		"article title": trackArticle.title,
		"categories": trackArticle.categories,
		"article title - issue name": trackArticle.title + " - " + trackArticle.issueName,
		"issue name": "_" + trackArticle.issueName,
		"issue date": new Date(trackArticle.issuePublicationDate).toISOString().slice(0, 10),
		"issue group": params.issueGroup,
		"template": trackArticle.customTemplate,
		"article id": trackArticle.articleId,
		"end event": params.trackingEndType === '' ? "User Action" : params.trackingEndType,
		"$duration": duration,
		"user has access": trackArticle.userHasAccess ? trackArticle.userHasAccess : true,
		"destination type": "article",
		"split": trackArticle.edition ? trackArticle.edition : "none",
	};
	if (params.hasOwnProperty("authenticationType") && params.authenticationType !== NO_LOGIN) {
		trackingProperties["subscriber status"] = isTrue(params.isLoggedIn) ? "subscriber" : "lookinside";
	}

	Tracking.libraryTrack("content viewed", trackingProperties);

	if (trackArticle.customTemplate === 'full-page-advertisement' || trackArticle.customTemplate === 'toc-advertorial') {
		trackingProperties.name = trackArticle.title;
		if (trackArticle.customTemplate === 'full-page-advertisement') {
			trackingProperties.location = "full-page-ad";
		} else if (trackArticle.customTemplate === 'toc-advertorial') {
			 trackingProperties.location = "advertorial";
		}
		Tracking.libraryTrack("advertisement viewed", trackingProperties);
	}
};


const generateNavigationParameters = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: [],
		articleId: ID_NOT_SET,
		zoomLevel: ''
	}, params);
	const articleList = params.articleList;
	const articleId = parseInt(params.articleId, 10);
	const destinationArticle = findArticle({articleList: articleList, articleId: articleId});
	const issueUrl = destinationArticle.hasOwnProperty('issueUrl') ? destinationArticle.issueUrl : '';
	// if section not explicitly passed in, check props
	const section = params.hasOwnProperty('section') ? params.section : (!isEmpty(params.props.section) ? params.props.section : '');

	// creates a clone of props; populates navigationKeys, and returns for moduleProps
	const moduleProps = populateFetchDataAttributes({
		props: params.props,
		data: destinationArticle
	});
	// viewing article; force articleViewer as first navigationKey
	const fetchNavigationKey = moduleProps.fetchNavigationKey !== null && moduleProps.navigationKeys.hasOwnProperty(moduleProps.fetchNavigationKey) ? moduleProps.fetchNavigationKey : 'article';
	if (moduleProps.navigationKeys.hasOwnProperty(fetchNavigationKey) && moduleProps.navigationKeys[fetchNavigationKey].hasOwnProperty('modules')) {
		moduleProps.navigationKeys[fetchNavigationKey].modules = reorderNavigationKeysModules({modules: moduleProps.navigationKeys[fetchNavigationKey].modules, key: 'articleViewer'});
	}

	const shareQueryParams = {};
	if (!isEmpty(section)) {
		shareQueryParams.section = section;
	}

	return {
		navigationKey: fetchNavigationKey,
		moduleProps: moduleProps,
		attributes: {
			articleId: articleId,
			issueUrl: issueUrl,
			articleList: articleList,
			fetchQueryParams: params.mops.queryParams,
			fromHistory: isTrue(moduleProps.navigationAttributes.fromHistory),
			replaceHistoryState: isTrue(moduleProps.replaceHistoryState),
			section: section,
			urlHash: !isEmpty(params.props.urlHash) ? params.props.urlHash : '',
			shareQueryParams: shareQueryParams,
			zoomLevel: !isEmpty(params.zoomLevel) ? params.zoomLevel : (!isEmpty(moduleProps.zoomLevel) ? moduleProps.zoomLevel : 1)
		}
	};
};


/**
 * Go to an article based on an articleId.  This triggers manageUpdateAndNavigation which manages
 * the display of the article and whether or not the url is built through share.  This is called
 * by both prev/next buttons and through refresh of the underlying article list for the viewer.
 *
 * It can also be called through the history back/next button, so we must pass the fromHistory
 * state to ensure that the history is not updated and the url populated when we are calling
 * from the browser back/next button.
 *
 * @param params
 *     props: module props
 *     mops: module mops
 *     articleList: article list for viewer
 *     articleId: article id to display
 */
const gotoArticle = (params) => {
	const navigationParameters = generateNavigationParameters(params);
	manageUpdateAndNavigation(navigationParameters);
};


/**
 * Find article in the list of articles based on articleId.
 *
 * If the articleId is -99999 (ID_NOT_SET) return the first article in the article list.
 *
 * Return
 *     hasArticle: boolean true/false if article found
 *     article: matching article
 *     content: the article content
 *     moduleClassName: className + 'empty' if no article content
 *     template: template name used to generate article
 *
 * @param params input parameters
 *     props: props from jsx
 *     mops: generated mops
 * @returns {{hasArticle: boolean, template: string, moduleClassName: string, article: {}, content: string}}
 */
const getArticle = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleList: [],
		currentArticleId: ID_NOT_SET
	}, params);
	const {articleList, currentArticleId} = params;
	const className = params.mops.className;

	let articleDetails = {
		hasArticle: false,
		article: {},
		content: '',
		moduleClassName: addClass(className, 'empty'),
		template: '',
	};
	if (!isEmpty(articleList) && articleList[0].articleId > 0) {
		const articleId = currentArticleId === ID_NOT_SET ? articleList[0].articleId : currentArticleId;
		const currentArticle = findArticle({articleList: articleList, articleId: articleId});

		if (!isEmpty(currentArticle)) {
			const articleContent = currentArticle.hasOwnProperty('content') ? currentArticle.content : '';
			const moduleClassName = articleContent !== '' ? className : addClass(className, 'empty');
			const docTypeDocumentTemplate = params.props.docTypeDocumentTemplate;
			const template = (!isEmpty(currentArticle.customTemplate) && currentArticle.customTemplate !== 'default') ? currentArticle.customTemplate : docTypeDocumentTemplate.template;

			articleDetails = {
				hasArticle: true,
				article: currentArticle,
				articleId: articleId,
				isGeneratedId: isTrue(currentArticleId === ID_NOT_SET),
				content: articleContent,
				moduleClassName: moduleClassName,
				template: template,
			};
		}
	}
	return articleDetails;
};


const trackLightboxOpen = (params) => {
	const target = params.target;
	const source = params.source;
	const alt = params.alt;
	const imageType = params.imageType;
	const matchDomain = new RegExp('(https*://|//|/|.*?)(.*?)(/|$)');
	const domain = source.match(matchDomain)[2];
	const $articleContainer = $(target).parents(".articleContainer");
	const trackingProperties = {
		label: (alt ? alt : source),
		module: 'articleViewer',
		type: "lightbox open",
		"image type": imageType,
		domain: domain,
		"article title": $articleContainer.find('.mainTitle').text().trim(),
		location: "modular library",
	};
	Tracking.libraryTrack("button clicked", trackingProperties);

};

const trackPageOpen = (params) => {
	const target = params.target;
	const source = params.source;
	const alt = params.alt;
	const imageType = params.imageType;
	const matchDomain = new RegExp('(https*://|//|/|.*?)(.*?)(/|$)');
	const domain = source.match(matchDomain)[2];
	const $articleContainer = $(target).parents(".articleContainer");
	const articleTitle = params.articleTitle || $articleContainer.find('.mainTitle').text().trim();
	const folio = params.folio;
	const trackingProperties = {
		label: "Open page image in lightbox",
		module: 'articleViewer',
		type: "page view",
		"image type": imageType,
		domain: domain,
		"article title": articleTitle,
		location: "modular library",
		"page folio": [ folio ],
	};
	Tracking.libraryTrack("content viewed", trackingProperties);

};

/**
 * Generate a Lightbox for article images
 */
const PictureLightbox = (params) => {
	params = Object.assign({
		articleData: {},
		articleList: [],
		props: {},
		mops: {}
	}, params);
	const article = params.articleData.article;
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		source: ''
	});
	const sources = [];
	const captions = [];
	const customAttributes = [];
	const $article = $('<div />');
	$article.append(article.content);
	const figures = $article.find('figure.picture,figure.graphic');
	for (let index = 0; index < figures.length; index++) {
		sources[index]=$(figures[index]).find('img').attr('src');
		captions[index]=<>{ReactHtmlParser( $(figures[index]).find('figcaption').html() || '' )}</>;
		customAttributes[index]={alt: $(figures[index]).find('img').attr('alt') };
	}

	const enableKeydownEventParams = {
		keyEvent: 'keydown',
		nameSpace: 'keydown.' + params.props.storageKey,
		eventElementId: params.props.myPane
	};
	const handleArrowEventParams = {
		supportedKeyCodes: [37,39],
		articleList: params.articleList,
		props: params.props,
		mops: params.mops,
	};
	const disableKeydownEventParams = {
		keyEvent: 'keydown',
		nameSpace: 'keydown.disabler.' + params.props.storageKey,
		eventElementId: params.props.myPane
	};
	const handleDisableEventParams = {
		supportedKeyCodes: [9],
		articleList: params.articleList,
		props: params.props,
		mops: params.mops,
	};

	const $app = $("#App");
	$app.off("click.picture-lightbox").on("click.picture-lightbox", "figure.picture a.lightboxPictureWrap,figure.graphic a.lightboxPictureWrap", function(e) {
		e.preventDefault();
		const img = $(e.currentTarget).find('img');
		const source = $(img).attr('src');
		const alt = $(img).attr('alt');
		trackLightboxOpen({target: $(img), source: source, alt: alt, imageType: "photo"});
		setLightboxController({
			toggler: !lightboxController.toggler,
			source: source
		});
	});

	return (
		<>
			<FsLightbox
				toggler={lightboxController.toggler}
				sources={sources}
				customAttributes={customAttributes}
				type="image"
				captions={captions}
				source={lightboxController.source}
				key={"i"+article.articleId}
				onOpen={(fsLightbox) => {
					// disable our article arrow key handling
					enableKeydownEventParams.enable = false;
					enableDisableKeyEvent({
						keyEventHandler: handleArrowKeyEvent,
						enableEventParams: enableKeydownEventParams,
						handleEventParams: handleArrowEventParams
					});
					// enable tab key capture
					disableKeydownEventParams.enable = true;
					enableDisableKeyEvent({
						keyEventHandler: handleDisableEvent,
						enableEventParams: disableKeydownEventParams,
						handleEventParams: handleDisableEventParams
					});
				}}
				onClose={(fsLightbox) => {
					// re-enable our article arrow key handling
					enableKeydownEventParams.enable = true;
					enableDisableKeyEvent({
						keyEventHandler: handleArrowKeyEvent,
						enableEventParams: enableKeydownEventParams,
						handleEventParams: handleArrowEventParams
					});
					// disable tab key capture
					disableKeydownEventParams.enable = false;
					enableDisableKeyEvent({
						keyEventHandler: handleDisableEvent,
						enableEventParams: disableKeydownEventParams,
						handleEventParams: handleDisableEventParams
					});
				}}
				onSlideChange={(fsLightbox) => {
					const img = fsLightbox.elements.sources[fsLightbox.stageIndexes.current].current;
					const source = fsLightbox.props.sources[fsLightbox.stageIndexes.current];
					const alt = $(img).attr('alt');
					trackLightboxOpen({target: $(img), source: source, alt: alt, imageType: "photo"});
				}}
			/>
		</>
	);
};

/**
 * Generate a Lightbox for page thumbnials
 */
const PageLightbox = (params) => {
	params = Object.assign({
		articleData: {},
		articleList: [],
		props: {},
		mops: {}
	}, params);
	const article = params.articleData.article;
	const [pageLightboxController, setPageLightboxController] = useState({
		toggler: false,
		source: ''
	});
	const sources = [];
	const customAttributes = [];
	const thumbnails = article.pageThumbnails ? article.pageThumbnails.split(',') : [];
	const pages = article.pages ? article.pages.split(',') : [];
	const folios = article.folios ? article.folios : [];
	let i=0;
	thumbnails.forEach((tn) => {
		sources.push(article.documentThumbnailUrl + '/data/imgpages/mobile3/'+ tn + '.jpg');
		customAttributes.push({"alt":"page "+pages[i],"data-folio":folios[i++],"data-article-title":article.title});
	});

	const enableKeydownEventParams = {
		keyEvent: 'keydown',
		nameSpace: 'keydown.' + params.props.storageKey,
		eventElementId: params.props.myPane
	};
	const handleArrowEventParams = {
		supportedKeyCodes: [37,39],
		articleList: params.articleList,
		props: params.props,
		mops: params.mops,
	};
	const disableKeydownEventParams = {
		keyEvent: 'keydown',
		nameSpace: 'keydown.disabler.' + params.props.storageKey,
		eventElementId: params.props.myPane
	};
	const handleDisableEventParams = {
		supportedKeyCodes: [9],
		articleList: params.articleList,
		props: params.props,
		mops: params.mops,
	};

	const $app = $("#App");
	$app.off("click.page-lightbox").on("click.page-lightbox", "li.page-thumbnail a.lightboxPageWrap", function(e) {
		e.preventDefault();
		const img = $(e.currentTarget).find('img');
		const source = $(img).attr('src').replace('mobile_tn2','mobile3').replace('.png','.jpg');
		const alt = $(img).attr('alt');
		const folio = $(img).data('folio') || '';
		trackPageOpen({target: $(img), source: source, alt: alt, imageType: "page", folio: folio});
		setPageLightboxController({
			toggler: !pageLightboxController.toggler,
			source: source
		});
	});

	return (
		<>
			<FsLightbox
				toggler={pageLightboxController.toggler}
				sources={sources}
				customAttributes={customAttributes}
				type="image"
				source={pageLightboxController.source}
				key={"p"+article.articleId}
				onOpen={(fsLightbox) => {
					// disable our article arrow key handling
					enableKeydownEventParams.enable = false;
					enableDisableKeyEvent({
						keyEventHandler: handleArrowKeyEvent,
						enableEventParams: enableKeydownEventParams,
						handleEventParams: handleArrowEventParams
					});
					// enable tab key capture
					disableKeydownEventParams.enable = true;
					enableDisableKeyEvent({
						keyEventHandler: handleDisableEvent,
						enableEventParams: disableKeydownEventParams,
						handleEventParams: handleDisableEventParams
					});
				}}
				onClose={(fsLightbox) => {
					// re-enable our article arrow key handling
					enableKeydownEventParams.enable = true;
					enableDisableKeyEvent({
						keyEventHandler: handleArrowKeyEvent,
						enableEventParams: enableKeydownEventParams,
						handleEventParams: handleArrowEventParams
					});
					// disable tab key capture
					disableKeydownEventParams.enable = false;
					enableDisableKeyEvent({
						keyEventHandler: handleDisableEvent,
						enableEventParams: disableKeydownEventParams,
						handleEventParams: handleDisableEventParams
					});
				}}
				onSlideChange={(fsLightbox) => {
					const img = fsLightbox.elements.sources[fsLightbox.stageIndexes.current].current;
					const source = fsLightbox.props.sources[fsLightbox.stageIndexes.current];
					const alt = $(img).attr('alt');
					const folio = $(img).data('folio') || '';
					const articleTitle = $(img).data('article-title') || '';
					trackPageOpen({target: $(img), source: source, alt: alt, imageType: "page", folio: folio, articleTitle: articleTitle});
				}}
			/>
		</>
	);
};



const hideLightbox = (e) => {
	const $lightbox = $(e.target).closest(".lightbox");
	if ($lightbox.is(':visible')) {
		e.stopPropagation();
		e.preventDefault();
		$lightbox.fadeOut({
			duration: 500,
			complete: function() {
				let $lightboxBg = $lightbox.find('.lightboxbg');
				// empty lightbox background if present
				if ($lightboxBg.length > 0) {
					console.log("empty out div", $lightboxBg);
					$lightboxBg.find('.lightbox-contents a').attr('href', '');
					$lightboxBg.find('.lightbox-contents a img').attr('alt', '');
					$lightboxBg.find('.lightbox-contents a img').attr('src', '');
					$lightboxBg.find('.adcaption').html('');
				}
			}
		});
	}
};

/**
 * Generate a Lightbox for fractional ads
 */
const AdLightbox = (params) => {
	params = Object.assign({
		articleData: {},
		articleList: [],
		props: {},
		mops: {}
	}, params);
	const article = params.articleData.article;
	const [adLightboxController, setAdLightboxController] = useState({
		toggler: false,
		sourceIndex: 0
	});
	const sources = [];
	const captions = [];
	const customAttributes = [];
	const $article = $('<div />');
	$article.append(article.content);
	const ads = $article.find('advertisement');
	const $app = $("#App");
	$app.off("click.ad-lightbox").on("click.ad-lightbox", "advertisement a.fullscreenIcon", function(e) {
		e.stopPropagation();
		e.preventDefault();
		const $img = $(e.currentTarget).parents('advertisement').find('a:not(.fullscreenIcon) img');
		const $a = $img.parents('a');
		const href = $a.attr('href');
		const adCaption = $(e.currentTarget).parents('advertisement').find('adcaption').html().trim() || '';
		const source = $img.attr('src');
		const alt = $img.attr('alt');
		trackLightboxOpen({target: $img, source: source, alt: alt, imageType: "advertisement"});
		const $lightbox = $("#adLightboxContainer");
		$lightbox.addClass("visible");
		$lightbox.find('.lightbox-contents a').attr('href', href);
		$lightbox.find('.lightbox-contents a img').attr('alt', alt);
		$lightbox.find('.lightbox-contents a img').attr('src', source);
		$lightbox.find('.adcaption').html(adCaption);
		const $focusElement = $(".lightboxbg");
		$lightbox.fadeIn({
			duration: 500,
			complete: function() {
				if ($focusElement.length > 0) {
					$focusElement.show();//.focus();
				} else {
					$lightbox.show();//focus();
				}
			}
		});

		setAdLightboxController({
			toggler: !adLightboxController.toggler,
			source: source
		});
	});

	return (<>
		<div id="adLightboxContainer"
			className="lightbox"
			tabIndex="-1" aria-modal="true" role="dialog"
			aria-label="Ad Lightbox">
			<div className="close-lightbox-contents-button">
				<button className="close-modal" onClick={hideLightbox}>
 					<i className="fal fa-times" />
					<span className="screen-reader">{Translate.Text({id: "article.modalLightbox.closeButton.text"})}</span>
 				</button>
			</div>
			<div className="lightboxbg">
				<div className="lightbox-contents">
					<a href={"//open_lightbox"} target="_blank" rel="noreferrer"><img alt={""} /></a>
				</div>
				<div className="adcaption" />
			</div>
		</div>
	</>);
};


/**
 * Generate the article jsx for display.
 *
 * The article template name comes from the template name in the article object and is matched to
 * a template definition in the templates/article directory.
 *
 * @param params article params
 * @returns {string|*} react object for article for display
 * @constructor
 */
const GenerateArticle = (params) => {
	params = Object.assign({
		props: {},
		mops: {},
		articleAttributes: {},
		articleData: {},
		searchFilters: {}
	}, params);
	const {props, mops, articleAttributes, articleData} = params;
	const articleList = !isEmpty(articleAttributes.articleList) ? articleAttributes.articleList : [];
	const moduleDOMElement = articleAttributes.moduleDOMElement;

	//Return empty string if no article
	if (!articleData.hasArticle) {
		// articleList will be empty on the first few renders
		if (!isEmpty(articleList)) {
			// bogus articleId: default to first article in list
			gotoArticle({
				articleId: articleData.articleId,
				articleList: articleList,
				props: props,
				mops: mops,
			});
		}
		return '';
	}

	const article = !isEmpty(articleData.article) ? articleData.article : {};
	const articleIdentifier = article.article !== ID_NOT_SET ? 'ARTICLE-' + article.articleId : '';
	const moduleDataAttributes = {
		'data-storagekey': props.storageKey,
		'data-issue-group': props.issueGroup,
		'data-issue-url': article.issueUrl,
		'style': {
			"--zoomLevel": articleAttributes.zoomLevel
		}
	};
	const DOMElementAttributes = {
		"data-article-id": article.articleId,
		"data-article-identifier": articleIdentifier,
	};

	const prevNextAttributes = {
		props: props,
		mops: mops,
		articleList: articleList,
		articleId: articleData.article.articleId,
		issueName: articleData.article.issueName,
		clickAction: gotoArticle,
		zoomLevel: articleAttributes.zoomLevel
	};

	const templateAlias = {
		'toc-advertorial': 'full-page-advertisement'
	};
	const templateName = articleData.hasOwnProperty('template')
		? !isEmpty(templateAlias[articleData.template]) ? templateAlias[articleData.template] : articleData.template
		: 'default';
	const moduleClassName = articleData.moduleClassName + " article-template_" + templateName;
	return (
		<div className={moduleClassName} {...moduleDataAttributes}>
			<PictureLightbox articleData={articleData} props={props} mops={mops} articleList={articleList} />
			<PageLightbox articleData={articleData} props={props} mops={mops} articleList={articleList} />
			<AdLightbox articleData={articleData} props={props} mops={mops} articleList={articleList} />
			<PreviousNextButton direction={'previous'} {...prevNextAttributes} />
			<GenerateTitle titleParams={mops.titleParams} />
			<div className="articleContainer layout_verticalScroll article-focus-element" tabIndex={-1} {...DOMElementAttributes} ref={moduleDOMElement} key={articleIdentifier}>
				<div className="articleContent">
					<GeneratedArticleContent articleData={articleData} moduleProps={props} searchFilters={params.searchFilters}/>
				</div>
			</div>
			<PreviousNextButton direction={'next'} {...prevNextAttributes} />
		</div>
	);
};

/**
 * Called whenever the articleViewer is rendered.  This means it gets called when the
 * article is clicked, changed, pane/view changes.  We check to see if we can find an
 * actual article in the list of articles to either:
 *     trigger an action if  the articleViewer pane is in view (isMyPane is true)
 *     clear out the current view and time if the view has been changed to a different pane.
 * In both cases, call to track the previous article if it exists
 *
 * Actions:
 *     Check if it is the articleViewer pane (isMyPane is true), if so
 *         Create new articleId if there was a change.
 *         Create start time at start of article view
 *         Based on change in articleId determine if this is the end of an article view
 *             If so calculate duration and make tracking call.
 *
 *     If article goes out of view (isMyPane is false)
 *         unset articleId (set to ID_NOT_SET)
 *
 *     Make sure re-entrant calls don't cause duplicate tracking calls.
 */
const articleViewed = (params) => {
	params = Object.assign({
		props: {},
		articleList: [],
		currentArticleId: ID_NOT_SET,
		trackingStartTime: 0
	}, params);
	const articleList = typeof params.articleList !== 'undefined' ? params.articleList : [];
	const currentArticleId = typeof params.currentArticleId !== 'undefined' ? params.currentArticleId : ID_NOT_SET;

	const currentArticle = findArticle({articleList: articleList, articleId: params.props.articleId});
	const trackArticle = currentArticleId !== ID_NOT_SET ? findArticle({articleList: articleList, articleId: currentArticleId}) : undefined;

	// Return values for setting new viewed article and start time
	let trackingEndTime = null;
	let newCurrentArticleId = null;

	if (params.props.isMyPane) {
		if (!isEmpty(currentArticle)) {
			const articleChanged = currentArticle.articleId !== params.currentArticleId;
			if (articleChanged) {
				trackingEndTime = new Date().getTime();
				newCurrentArticleId = currentArticle.articleId;
				// call tracking since the article has changed
				// note: the tracking function will check to make sure the stored articleId is not ID_NOT_SET
				if (typeof trackArticle !== "undefined") {
					trackArticleView({
						trackArticle: trackArticle,
						trackingEndTime: trackingEndTime,
						trackingStartTime: params.trackingStartTime,
						authenticationType: params.props.authenticationType,
						isLoggedIn: params.props.isLoggedIn,
						issueGroup: params.props.issueGroup,
					});
				}
			}
		}
		// call tracking if we have changed out of the article pane but still have the articleId stored away
	} else if (typeof trackArticle !== "undefined") {
		trackingEndTime = new Date().getTime();
		newCurrentArticleId = ID_NOT_SET;
		trackArticleView({trackArticle: trackArticle,
			trackingEndTime: trackingEndTime,
			trackingStartTime: params.trackingStartTime,
			authenticationType: params.props.authenticationType,
			isLoggedIn: params.props.isLoggedIn,
			issueGroup: params.props.issueGroup,
		});
	}

	return {newCurrentArticleId: newCurrentArticleId, newStartTime: trackingEndTime};
};


/**
 * Handle keystroke events for previous/next navigation.  As part of the function call,
 * the actual keystroke event will get passed into the function and checked to see
 * if it is applicable to navigation.
 *
 * Once the direction is setup, then call the generic previousNextClicked function
 * to handle going to previous/next article.
 *
 * @param event keystroke event
 * @param params
 *     articleList: articleList used by the module
 *     props: module props
 *     mops: module mops
 */
const handleArrowKeyEvent = (event, params) => {
	params = Object.assign({
		supportedKeyCodes: [37,39],
		articleList: [],
		props: {},
		mops: {}
	}, params);

	const props = clone(params.props);
	const mops = clone(params.mops);
	const keyCode = event.keyCode;

	// only run if this is my pane and articleList contains articles and it is a supported keyCode
	if (props.isMyPane && params.articleList.length > 0 && params.supportedKeyCodes.includes(keyCode)) {
		const previousNextIds = getPreviousNextIds(props.articleId, params.articleList);
		let keyEventArticleId;
		let direction;
		if (keyCode === 37) {
			keyEventArticleId = previousNextIds.previousId;
			direction = 'Previous Page';
		} else {
			keyEventArticleId = previousNextIds.nextId;
			direction = 'Next Page';
		}
		const articleClickParams = {
			articleId: keyEventArticleId,
			props: props,
			mops: mops,
			articleList: params.articleList,
			eventType: 'key',
			direction: direction,
			clickAction: gotoArticle
		};
		// fire the navigation only if articleId and keyEventArticleId are valid
		if (props.articleId !== ID_NOT_SET && keyEventArticleId > -1) {
			previousNextClicked( {event: params.event, articleClickParams: articleClickParams} );
		}
	}
};

const handleDisableEvent = (event, params) => {
	params = Object.assign({
		supportedKeyCodes: [9],
		articleList: [],
		props: {},
		mops: {}
	}, params);
	const props = clone(params.props);
	const keyCode = event.keyCode;
	if (props.isMyPane && params.supportedKeyCodes.includes(keyCode)) {
		if (params.supportedKeyCodes.includes(keyCode)) {
			event.stopPropagation();
			event.preventDefault();
		}
	}
};


/**
 * Callback to handle swipe events for previous/next navigation
 * This function expects the following passed back from the hook:
 *     - event (event): the swipe event
 *     - swipeDirections (array): array of directions detected by the handler: left, right, up, down
 *     - articleList (array): array of articles
 *     - props (object): passed to previousNextClicked with articleClickParams
 *     - mops (object): passed to previousNextClicked with articleClickParams
 */
const handleSwipeEvent = (params) => {
	params = Object.assign({
		event: null,
		swipeDirections: [],
		articleList: [],
		props: {},
		mops: {}
	}, params);

	const props = params.props;
	const mops = params.mops;

	// only run if this is my pane, articleList contains articles, and swipeDirections contains at least one direction
	if (props.isMyPane && params.articleList.length > 0 && params.swipeDirections.length > 0) {
		const previousNextIds = getPreviousNextIds(props.articleId, params.articleList);
		let swipeEventArticleId;
		let direction;
		if (params.swipeDirections.includes('right')) {
			swipeEventArticleId = previousNextIds.previousId;
			direction = 'Previous Page';
		} else if (params.swipeDirections.includes('left')) {
			swipeEventArticleId = previousNextIds.nextId;
			direction = 'Next Page';
		}
		const articleClickParams = {
			articleId: swipeEventArticleId,
			props: props,
			mops: mops,
			articleList: params.articleList,
			eventType: 'swipe',
			direction: direction,
			clickAction: gotoArticle
		};
		// fire the navigation only if articleId and swipeEventArticleId are valid
		if (props.articleId !== ID_NOT_SET && swipeEventArticleId > -1) {
			previousNextClicked( {event: params.event, articleClickParams: articleClickParams} );
		}
	}
};

/**
 * Start of jsx code for module
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
export const ArticleViewerModule = (props) => {
	// updating value triggers re-render; for pagination
	const [paginationWidth, setPaginationWidth] = useState(window.innerWidth);
	// store serialized data to keep the last "valid" list
	const [articleList, setArticleList] = useState([]);
	const [fetchInProgress, setFetchInProgress] = useState(false);
	const [highlightedArticleId, setHighlightedArticleId] = useState(ID_NOT_SET);

	// keep track of the (last) current article and time it was viewed for tracking only
	const [currentArticleId, setCurrentArticleId] = useState(ID_NOT_SET);
	const [trackingStartTime, setTrackingStartTime] = useState(new Date().getTime());

	const [zoomLevel, setZoomLevel] = useState(DEFAULT_ARTICLE_ZOOM_LEVEL);


	// Callback only once when module is setup.
	useMountPostRender(() => {
		// setup to trigger change if the device size value changes
		manageDeviceResize(setPaginationWidth);
		const configurationArticleLayout = getObjectFromJSON(props.articleLayout, {});
		const initialArticleLayout = deviceArticleLayout(configurationArticleLayout);
		setZoomLevel(initialArticleLayout.zoomLevel);
	});

	useEffect(() => {
		const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
		genericUpdateData({currentArticleId: currentArticleId},{type: GLOBALS});
	}, [currentArticleId]);

	useEffect(() => {
		if (!isEmpty(props.zoomLevel) && zoomLevel !== props.zoomLevel) {
			setZoomLevel(props.zoomLevel);
		}
	}, [props.zoomLevel, zoomLevel]);

	/**
	 * Call to generate module-specific props, but that are generally common to all library
	 * modules.
	 * Generally, returns titleParams, className, storageKey, queryParams
	 *
	 * Note: this should be called very early so that subsequent functions can use these.
	 *
	 * @type {{queryParams: {}, titleParams: {}, className: string, storageKey: string}}
	 */
	const mops = generateMops(props, {
		defaultKey: ARTICLE_VIEWER,
		defaultClass: addClass('article-viewer', ['article', props.className]),
		title: 'articleViewer.title',
		titleTag: 'h2',
		displayTitle: false,  // for articles, we usually don't want to display a title
		configQueryParams: configQueryParams
	});

	useEffect(() => {
		if (!isEmpty(articleList) && props.articleId !== ID_NOT_SET) {
			const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
			const currentArticle = findArticle({articleList: articleList, articleId: props.articleId});
			if (!isEmpty(currentArticle)) {
				genericUpdateData({
					share: {
						summary: !isEmpty(currentArticle.summary) ? currentArticle.summary : '',
						articleId: !isEmpty(props.articleId) ? props.articleId : '',
						articleTitle: !isEmpty(currentArticle.title) ? currentArticle.title : '',
						issueName: !isEmpty(currentArticle.issueName) ? currentArticle.issueName : '',
						category: !isEmpty(currentArticle.categories) ? currentArticle.categories : [],
					}
				},{type: GLOBALS, assign: "merge"});
			}
		}
	}, [articleList, props.articleId]);


	// setup ref to the article parent element to listen for when the article view changes
	// and we need to set focus, document title, or call article triggers.
	// Call function to set DOM properties if the DOM element exists and to trigger
	const moduleDOMElement = useRef(null);
	const DOMElementReady = props.isMyPane &&
		props.articleId !== ID_NOT_SET &&
		!isEmpty(moduleDOMElement) &&
		!isEmpty(moduleDOMElement.current) &&
		valuesEqual(moduleDOMElement.current.dataset.articleId, props.articleId);

	useAttributesChanged(() => {
		const currentArticle = findArticle({articleList: articleList, articleId: props.articleId});
		// check again to make sure element matches article
		const elementIdMatch = !isEmpty(moduleDOMElement.current) && valuesEqual(moduleDOMElement.current.dataset.articleId, currentArticle.articleId);
		if (DOMElementReady && elementIdMatch) {
			triggerOnArticle({
				module: 'articleViewer',
				storageKey: props.storageKey,
				article: currentArticle,
				triggerProperties: {
					action: 'viewed',
					section: props.section,
					urlHash: props.urlHash,
				},
				DOMProperties: {
					moduleDOMElement: moduleDOMElement.current,
					useModuleTitle: props.useModuleTitle,
					useModuleFocus: props.useModuleFocus,
					titleValue: currentArticle.hasOwnProperty('title') ? currentArticle.title : ''
				}
			});
			updateArticleDataTables(moduleDOMElement.current, props.showDataTables);
			const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
			genericUpdateData({
				"showFootnotes": props.showFootnotes,
				"footnotes": currentArticle.footnotes,
				"articleTitle": currentArticle.title,
				"issueName": currentArticle.issueName
			},{type: GLOBALS, storageKey: FOOTNOTE_KEY});
		} else {
			setTemplateClass({action: 'reset'});
		}
	}, [DOMElementReady, articleList, props.articleId, props.section, props.showDataTables, props.showFootnotes, props.storageKey, props.useModuleFocus, props.useModuleTitle]);


	//anytime article id/list changes fetch the highlights for the new article
	useAttributesChanged(() => {
		const currentArticle = findArticle({"articleId": props.articleId, "articleList": articleList});
		// check again to make sure element matches article
		const elementIdMatch = !isEmpty(moduleDOMElement.current) && valuesEqual(moduleDOMElement.current.dataset.articleId, currentArticle.articleId);
		const processHighlights = !isEmpty(currentArticle) && isTrue(props.highlighting) && isEmpty(props.searchEntryValue) && DOMElementReady && elementIdMatch;
		if ( processHighlights ) {
			retrieveHighlights(props.articleId);
			setHighlightedArticleId(ID_NOT_SET);//reset highlighted articleid
			webHighlighter({articleId: props.articleId, articleTitle: currentArticle.title, issueUrl: currentArticle.issueUrl,root: moduleDOMElement.current});
		}
	}, [DOMElementReady, articleList, props.articleId, props.highlighting, props.searchEntryValue]);


	useAttributesChanged(() => {
		if (!props.highlighting || (highlightedArticleId === props.articleId)) {
			return;
		}
		const curr = !isEmpty(moduleDOMElement.current) ? moduleDOMElement.current : null;
		let highlights = props.highlights;

		if (!isEmpty(highlights)) {
			if (!Array.isArray(highlights)) {
				highlights = Object.values(highlights);
			}
			highlights = highlights.filter(highlight => highlight.articleId === props.articleId) ;
		}
		if (!isEmpty(highlights) && !isEmpty(curr)) {
			displayHighlights({highlights:highlights, root:curr});
			setHighlightedArticleId(props.articleId);
		}
	}, [props.highlighting, props.highlights, props.articleId, highlightedArticleId, DOMElementReady]);


	/**
	 * Callback on changes to
	 *     articleList
	 * Note: use our own hook as it uses JSON.stringify rather than pointers to register changes
	 * Note2: since this uses props.articleList to look for changes, it can be set after
	 * the previous hook which sets the state variable for articleList
	 */
	useAttributesChanged(() => {
		setupLinkTracking({
			articleList: articleList,
			issueGroup: props.issueGroup,
		});
		if (articleList.length > 0) {
			const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
			genericUpdateData({
				currentViewedDocumentId: articleList[0].documentId,
			},{type: GLOBALS});
		}
	}, [articleList], props.issueGroup);

	/**
	 * Called to manage whether the module fetches data.  If the hook calls the
	 * callback, then call getArticleList which will determine whether to
	 * fetch a new list or get an existing list from stored data.
	 *
	 * Note: this uses mops, so that must be defined first.
	 * Currently only uses 'fetchOnInit' and 'forceFetch' from props
	 *
	 */
	useFetchAttributesChange(() => {
		const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams});
		if (!isEmpty(storedListData.articleList)) {
			setArticleList(storedListData.articleList);
			if (!isEmpty(props.articleId) && props.articleId !== ID_NOT_SET) {
				const article = findArticle({articleList: storedListData.articleList, articleId: props.articleId});
				if (!isEmpty(article)) {
					setCurrentArticleId(props.articleId);
				}
			}
		}
		setFetchInProgress(isTrue(storedListData.fetchInProgress));
	}, {type: ARTICLE_LIST, props: props, queryParams: mops.queryParams});

	/**
	 * Call hook to check to see if the fetchInProgress has been set/changed in the reducer.
	 * fetchInProgress is set true when fetch is started (DATA_REQUESTED)
	 * and set to false when fetch is done (DATA_LOADED)
	 */
	useFetchComplete((isInProgress) => {
		isInProgress = isTrue(isInProgress, {defaultValue: false});
		setFetchInProgress((isInProgress));
		if (!isInProgress) {
			const storedListData = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
			setArticleList(storedListData.articleList);
		}
	}, {requestInProgress: props.fetchInProgress, fetchInProgress: fetchInProgress});

	/**
	 * If articleId is generated from first entry in articleList, then call to update all navigationKeys for
	 * the articleList module and storageKey.
	 * This helps with generating url with first articleId rather than -99999
	 *
	 */
	useAttributesChanged(() => {
		const storedArticleList = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});

		if (!isEmpty(storedArticleList.articleList) && props.generatedArticleId && currentArticleId !== props.articleId) {
			setCurrentArticleId(props.articleId);
			openArticle(null,{
				mops: mops,
				moduleProps: props,
				issueUrl: !isEmpty(mops.queryParams.issueUrl) ? mops.queryParams.issueUrl : '',
				articleList: storedArticleList.articleList,
				articleId: props.articleId,
				zoomLevel: zoomLevel,
				isCurrentArticle: false,
				setShareHistory: false,
			});

				//	TODO: Replace the openArticle call with the manageUpdate call when setting the url changes
//				const updateParams = {
//					navigationKey: !isEmpty(props.navigationKey) ? props.navigationKey : 'replica',
//					navigationKeys: !isEmpty(props.navigationKeys) ? props.navigationKeys : {},
//					attributes: {articleId: props.articleId}
//				};
//				manageUpdate(updateParams);

		}
	},[mops.queryParams, props.articleId, props.generatedArticleId]);


	// set the url if the article changes and after the DOM has loaded
	// update for article/articleList changed in articleViewData store object
	useAttributesChanged(() => {
		const storedArticleList = getArticleList({storageKey: mops.storageKey, queryParams: mops.queryParams, returnStoredDataOnly: true});
		const currentArticle = findArticle({articleList: storedArticleList.articleList, articleId: props.articleId});
		if (DOMElementReady && !isEmpty(storedArticleList.articleList) && !isEmpty(currentArticle)) {
			updateShareUrl(
				generateNavigationParameters({
					props: props,
					mops: mops,
					articleList: storedArticleList.articleList,
					articleId: props.articleId,
				})
			);
			const genericUpdateData = getStoreValue({attributeKey: 'genericUpdateData'});
			genericUpdateData({articleViewData: {articleList: storedArticleList.articleList, articleId: props.articleId, issueUrl: props.issueUrl}}, {type : ARTICLE_ARTICLEVIEW, storageKey: props.storageKey});
		}
	},[DOMElementReady, mops.queryParams, props.articleId]);


	/**
	 * Setup hook to register and callback if the articleViewer goes out of view.
	 * Note: we don't currently use the triggerStatus return
	 *
	 * Callback passes three pieces of data to the callback function
	 *     time: end time for tracking
	 *     message: tracking message
	 *     status: status of check
	 *
	 * @type {string}
	 */
	const triggerStatus = useVisibilityChange((params) => {
		params = Object.assign({
			time: 0,
			message: '',
			status: '',
			props: {}
		}, params);
		if (currentArticleId > 0) {
			const trackArticle = findArticle({articleList: articleList, articleId: currentArticleId});
			trackArticleView({trackArticle: trackArticle,
				trackingEndTime: params.time,
				trackingEndType: params.message,
				currentArticleId: currentArticleId,
				trackingStartTime: trackingStartTime,
				authenticationType: params.props.authenticationType,
				isLoggedIn: params.props.isLoggedIn
			});
			setTrackingStartTime(params.time);
		}
	}, setTrackingStartTime, props);


	/**
	 * Setup hook that handles keystroke events.  This hook just keeps track of key detection/handling
	 * attributes and calls back to enable/disable keystroke detection and to set the function that
	 * handles what happens on a key stroke.
	 *
	 * keyEventParams
	 *     enableDisableHandler: function to enable/disable keystroke detection; usually use default
	 *     keyEventHandler: function to handle keystroke; must define in module
	 *     enableEventParams: object of key detection attributes
	 *         keyEvent: key event to listen for
	 *         supportedKeyCodes: which key codes to listen for
	 *         nameSpace: for event enable/disable
	 *         eventElementId: element to which to attach the listener; should be the module's pane
	 *         enable: true: enable listener; false: disable listener
	 *     handleEventParams: object of attributes used by the event handler
	 *         articleList: pass current list to handler
	 *         props: mobule props
	 *         mops: module mops
	 */
	const keyEventParams = {
		enableDisableHandler: enableDisableKeyEvent,
		keyEventHandler: handleArrowKeyEvent,
		enableEventParams: {
			supportedKeyEvents: ['keyup','keydown','keypress'],
			keyEvent: 'keydown',
			nameSpace: 'keydown.' + props.storageKey,
			eventElementId: props.myPane,
			enable: props.isMyPane
		},
		handleEventParams: {
			supportedKeyCodes: [37,39],
			articleList: articleList,
			props: props,
			mops: mops,
		}
	};
	useKeyEvents((enableDisableHandler, params) => {
		enableDisableHandler(params);
	}, keyEventParams);


	/**
	 * Setup hook that handles the swipe events
	 *     supportSwipeDirections: (array) which directions to support, could be 'left', 'right', 'up', or 'dowwn'
	 *     storageKey: (string) the calling module's storageKey, used to identify
	 *                 the swipe-enabled container and namespace the event handlers
	 *     callbackParams: (object) params needed by the callback
	 *
	 * Note: TEMPORARILY DISABLED

	const swipeEventParams = {
		supportSwipeDirections: ['left','right'], // only respond to swipe left and right
		storageKey: props.storageKey,
		callbackParams: {
			articleList: articleList,
			props: props,
			mops: mops
		}
	};
	useSwipeEvents((event, params) => {
		handleSwipeEvent({
			event: event,
			swipeDirections: params.swipeDirections,
			articleList: params.callbackParams.articleList,
			props: params.callbackParams.props,
			mops: params.callbackParams.mops
		});
	}, swipeEventParams);
	 */


	/**
	 * Call hook that checks if layout container has been scrolled and whether fractional ads,
	 * html tag: "advertisement", have been scrolled into the viewable area.  We use a value
	 * indicating that 60% of the ad must be visible to trigger a "seen" callback trigger.
	 * Set an event check delay of 1/2 second, so that if the user scrolls very fast through
	 * the article, the "seen" trigger won't be registered.
	 * Finally, we only want the ad to register as "seen" once per session, so we keep track
	 * of whether or not it has been tracked as viewed.
	 */
	const scrollElement = getModuleContainer({module: props.name, instanceId: props.instanceId}).layoutSelector;
	const fractionalAdScrollAttributes = Object.assign({
		scrollContainer: getNodeFromClass(document, scrollElement),
		namespace: 'fractionalAd',
		elements: getChildNodes(moduleDOMElement.current, 'advertisement'),
		percentInView: 60,
		triggerDelay: 500,
		triggerOn: "resetOnCollection",
		collectionId: !isEmpty(props.articleId) && props.articleId !== ID_NOT_SET ? props.articleId : '',  // required for "resetOnCollection" style tracking
	}, props.fractionalAdTracking);
	useScrolledIntoView((elementsInView) => {
		// track all fractional ad views; multiple as multiple ads could be visible in the
		// vieweable area at the same time
		elementsInView.forEach(viewed => {
			if (isTrue(viewed.triggerInView, {defaultValue: false})) {
				trackAdsAndLinks({
					articleId: props.articleId,
					issueGroup: props.issueGroup,
					articleList: articleList,
					element: viewed.element,
					category: "advertisement viewed",
				});
			}
		});
	}, fractionalAdScrollAttributes);



	/*
	* Generate jsx for article and wrappers if displayOnDevice
	* Call GenerateArticle function to generate html
	*/
	if (displayOnDevice(props)) {
		const newTrackingAttr = articleViewed({props: props, currentArticleId: currentArticleId, articleList: articleList, trackingStartTime: trackingStartTime});
		// reset stored articleId and start time
		if (newTrackingAttr.newStartTime !== null) {
			setCurrentArticleId(newTrackingAttr.newCurrentArticleId);
			setTrackingStartTime(newTrackingAttr.newStartTime);
		}

		const articleAttributes = {
			articleList: articleList,
			currentArticleId: currentArticleId,
			moduleDOMElement: moduleDOMElement,  // eventually pass this object to GeneratePage
			DOMElement: moduleDOMElement.current,
			DOMElementReady: DOMElementReady,
			zoomLevel: zoomLevel
		};

		const articleData = getArticle({props: props, mops: mops, articleList: articleAttributes.articleList, currentArticleId: articleAttributes.currentArticleId});
		if (articleData.isGeneratedId && props.isMyPane && articleData.articleId !== ID_NOT_SET) {
			if (currentArticleId !== articleData.articleId) {
				setCurrentArticleId(articleData.articleId);
			}
		}
		// note: this uses the state value for articleList rather than the one set in props
		return (
			fetchInProgress ?
				props.displayInProgressPage ? <GenerateInProgressPage displaySpinner={true} /> : ''
				:
				<>
					<GenerateArticle props={props} mops={mops} articleAttributes={articleAttributes} articleData={articleData} searchFilters={props.searchFilters} />
				</>
		);
	} else {
		return null;
	}
};


/**
 * Map state (store) data for the articleViewer module; added to module props.
 *
 * articleList (array of articles objects)
 *     id (article id in UPP) - not currently available
 *     articleId (article id in WDS)
 *     publicationId
 *     publication (publication name)
 *     issueId
 *     articleUrl (The webreader link to the article)
 *     title
 *     subtitle
 *     byline (Comma separated list of authors)
 *     thumbnail (article image thumbnail src)
 *     sortOrder (The order in which the article are displayed in the issue. Articles should be returned in sort order.
 *     summary (This is a short summary of the article)
 *     categories - comma separated list of categories
 *
 * @param state store state
 * @param props module props, passed through action to store and back
 * @returns {{articleList: Array}}
 */
const mapStateToProps = (state, props) => {
	const storageKey = !isEmpty(props.storageKey) ? props.storageKey : ARTICLE_VIEWER;
	const storeState = !isEmpty(state[storageKey]) ? state[storageKey] : {};
	const globalState = !isEmpty(state.globals) ? state.globals : {};

	const highlightStoreState = state[HIGHLIGHTS_KEY];
	const loginState = state.LOGIN ? state.LOGIN : {};

	const searchFilters = storeState.hasOwnProperty('searchFilters') ? storeState.searchFilters : {};

	let articleProps = {
		storageKey: storageKey,
		canDisplayShareButtons: isTrue(props.canDisplayShareButtons),
		displayShareButtons: isTrue(storeState.displayShareButtons),
		pageThumbnailOpensReplicaViewer: isTrue(props.pageThumbnailOpensReplicaViewer),
		overrideIssueButtonTitle: isTrue(props.overrideIssueButtonTitle),

		// Authentication and logged-in state used to determine new fetch
		isLoggedIn: isTrue(globalState.isLoggedIn, {defaultValue: false}),
		authenticationType: state.authenticationType ? state.authenticationType : '',

		isPurchaseSuccessful: isTrue(globalState.isPurchaseSuccessful),
		isLoginProcessing: isTrue(loginState.isProcessing),
		purchaseProductId: !isEmpty(globalState.purchaseProductId) ? globalState.purchaseProductId : "",

		templateOverrides: !isEmpty(props.templateOverrides) ? getObjectFromJSON(props.templateOverrides, {}) : {},
		highlighting: isTrue(props.highlighting),
		section: !isEmpty(storeState.section) ? storeState.section : '',
		searchFilters: searchFilters,
		searchEntryValue: !isEmpty(searchFilters.entry) ? searchFilters.entry : '',
		highlights: highlightStoreState,
		urlHash: !isEmpty(storeState.urlHash) ? storeState.urlHash : '',
		zoomLevel: !isEmpty(storeState.zoomLevel) ? getFloatValue(storeState.zoomLevel) : '',
		fetchInProgress: isTrue(storeState.fetchInProgress, {defaultValue: false}),
		displayInProgressPage: isTrue(props.displayInProgressPage, {defaultValue: true}),
		generatedArticleId: false,  // keep track of whether or not we generate articleId later
		fractionalAdTracking: !isEmpty(props.fractionalAdTracking) ? props.fractionalAdTracking : {},
	};
	// add module properties that are common to data modules
	articleProps = dataModuleAttributes({
		moduleProps: articleProps,
		originalProps: props,
		state: state,
		storageKey: storageKey,
		navigationAttributeType: 'articles'
	});

	articleProps.issueId = storeState.hasOwnProperty('issueId') ? parseInt(storeState.issueId, 10) : 0;
	articleProps.issueId = isNaN(articleProps.issueId) ? 0 : articleProps.issueId;
	articleProps.docType = getDocType({documentList: state.documentList, issueUrl: storeState.issueUrl, docTypeOverride: storeState.docType});
	// generate values for the article based on docType
	articleProps.className = getDocTypeAttribute(articleProps.docType, props.className);
	articleProps.docTypeDocumentTemplate = getDocTypeAttribute(articleProps.docType, props.documentTemplate);
	articleProps.docTypeDocumentTemplate.template = !isEmpty(articleProps.docTypeDocumentTemplate.template) ? articleProps.docTypeDocumentTemplate.template : 'default';

	// TODO: see if we can get rid of this as it doesn't seem to be used
	articleProps.wdsArticleId = storeState.hasOwnProperty('wdsArticleId') ? parseInt(storeState.wdsArticleId, 10) : 0;
	articleProps.wdsArticleId = isNaN(articleProps.wdsArticleId) ? 0 : articleProps.wdsArticleId;

	// get articleList from previously fetched article list that matches query params
	const queryParams = generateQueryParams({configQueryParams: configQueryParams, moduleProps: Object.assign({}, props, articleProps)});
	const storedListData = getArticleList({storageKey: storageKey, queryParams: queryParams, returnStoredDataOnly: true});
	articleProps.articleList = storedListData.articleList;
	// articleViewer needs to know which article is currently selected
	if (!articleProps.isMyPane) {
		articleProps.articleId = ID_NOT_SET;
	} else if (storeState.hasOwnProperty('articleId') && !(storeState.articleId === ID_NOT_SET)) {
		articleProps.articleId = parseInt(storeState.articleId, 10);
	} else if (!isEmpty(articleProps.articleList)) {
		articleProps.articleId = parseInt(articleProps.articleList[0].articleId, 10);
		articleProps.updateHistoryState = true;
		articleProps.generatedArticleId = true;
	} else {
		articleProps.articleId = ID_NOT_SET;
	}
	articleProps.articleId = isNaN(articleProps.articleId) ? ID_NOT_SET : articleProps.articleId;

	return articleProps;
};


/**
 * Actions that can be called by this module.  Each action is added to props so
 * that it can be called in the module, but defined in a single place with
 * appropriate parameters for the action call by this module.
 *
 * Potential query parameters (set in configuration)
 *     pageSize: (default: 200) maximum number of entries to return
 *         property: number
 *     categories: (default: all categories) comma separated list of categories to filter
 *         categories are set in article editor for article
 *         properties: comma separated list of category names
 *     excludeContentTypes: (default: none, ie. show all) comma separated list of article content types to filter out
 *         matching articles.  These types are (generally) generated based on the article template.  Article content
 *         types include:
 *             cover - generated based on cover template
 *             advertisement - generated based on template with advertisement in the name (full-page-advertisement)
 *             article - all other articles
 *     issues: (default: all issues) comma separated list of issues to filter
 *         ( what is better - issue ids as in group id or issue names )
 *     'recentIssues': (default: all issues) number of most recent issues to return
 *         property: number
 *         ex. 'recentIssues': 5
 *     'pageNumber': (default: 0) starting page number of full results
 *     sortBy: (default: "publishedDate-desc") sort the results
 *         properties: "sortOrder"|"issueName"|"publishedDate"|"category" + "-" + "asc"|"desc"
 *         includes sort order
 *         comma separated list - whatever is first is the top level sort, the second one is within that sort by the next
 *         default sort orders
 *             "sortOrder-asc"
 *             "issueName-asc"
 *             "publishedDate-desc"
 *             "category-asc"
 *     publicationIds: (default: current publication for collection url) for multiple collections in future
 *         properties: comma separated list of publication ids
 *             specify in the order you want the publications to appear
 *
 * actions:
 *     fetchData will make a call to the server to get data
 *
 * @param dispatch call action
 * @returns {{updateData: updateData}}
 */
function mapDispatchToProps(dispatch) {
	return {
		fetchData: (params) => {
			params.type = params.hasOwnProperty('type') ? params.type : ARTICLE_LIST;
			dispatch(fetchData(params));
		},
		updateData: (payload, params) => {
			params.type = params.hasOwnProperty('type') ? params.type : ARTICLE_VIEWER;
			dispatch(updateData(payload, params));
		}
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleViewerModule);
