import React, { useState, useEffect } from 'react';
import {
	AUTHENTICATION,
	AUTHENTICATION_KEY,
	JWT_LOGIN,
	REFERRER_LOGIN} from "../../../_MODULE_GLOBALS/constants";
import {Translate} from "../../../../locales/locales";
import {getStoreValue} from "../../../../utils/storeValue";
import * as Tracking from "../../../../utils/tracking";
import {trackButtonClick} from "../index";
import {displayOnDevice} from "../../../../utils/moduleSetup";
import {isEmpty, isTrue} from "../../../../utils/generalUtils";


/**
 * Handle click on the Subscribe button, including tracking call.
 *
 * @param params
 */
const subscribeButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	}));
	if (window) {
		let subscribeUrl = params.subscribeUrl;
		if (subscribeUrl.toUpperCase().endsWith("RETURNURL=") || subscribeUrl.toUpperCase().endsWith("MAGURL=")) {
			subscribeUrl += encodeURIComponent(window.location.href);
		}
		window.location.href = subscribeUrl;
	}
};

/**
 * Handle click on the Login button (for refurl), including tracking call.
 *
 * @param params
 */
const loginButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "login link",
	}));
	if (window) {
		let subscribeUrl = !isEmpty(params.collectionSubscribeUrl) ? params.collectionSubscribeUrl : params.subscribeUrl;
		if (subscribeUrl.toUpperCase().endsWith("RETURNURL=") || subscribeUrl.toUpperCase().endsWith("MAGURL=")) {
			subscribeUrl += encodeURIComponent(window.location.href);
		}
		window.location.href = subscribeUrl;
	}
};

/**
 * Handle click on the JWT Subscribe button, including tracking call.
 *
 * @param params
 */
const jwtSubscribeButton = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		action: "subscribe link",
	}));
	if (window) {
		// expecting collectionSubscribeUrl == 'https://app.hapara.com'
		window.location.href = params.collectionSubscribeUrl + "/resource/" + params.resourceId + "?redirect_url=" + encodeURIComponent(window.location.href);
	}
};

/**
 * Handle click on the login button, including tracking call.
 *
 * @param params
 */
const loginLink = (params) => {
	trackButtonClick( Object.assign({}, params.baseTrackingProperties, {
		label: params.label,
		type: "toggle access",
		action: "login link",
	}));
	if (params.authenticationType === REFERRER_LOGIN) {
		loginButton(params);
	} else if (params.authenticationType === JWT_LOGIN) {
		jwtSubscribeButton(params);
	} else {
		const updateData = getStoreValue({attributeKey: 'genericUpdateData'});
		// set email login and clear previous email address
		updateData({emailAddress: ''}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY});
		updateData({authView: 'login'}, {type: AUTHENTICATION, storageKey: AUTHENTICATION_KEY });
	}
};


/**
 * Widget for the "prompt" dialog
 *
 * @param params props + additionalProps passed in from the authentication parent module
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticatePrompt = (params) => {
	const displayText = {
		promptLabel: Translate.Text({id:'authenticate.prompt.button'}),
		promptTitle: Translate.Text({id:'authenticate.prompt.title'}),
		promptCopy: Translate.Text({id:'authenticate.prompt.copy'}),
		promptLogin: Translate.Text({ id: 'authenticate.prompt.loginPrompt' }),
		loginLabel: Translate.Text({ id: 'authenticate.prompt.loginLink' }),
		loginLabelJWT: Translate.Text({ id: 'authenticate.promptJWT.loginLink' }),
		loginTitleJWT: Translate.Text({id:'authenticate.promptJWT.title'}),
	};

	const authParams = {
		authenticationType: params.authenticationType,
		subscribeUrl: params.subscribeUrl,
		collectionSubscribeUrl: params.collectionSubscribeUrl,
		resourceId: params.resourceId,
		// setup base attributes for tracking for this widget
		baseTrackingProperties: {
			"destination type": params.popupType,
			"issue name": "_" + params.issueName,
			"issue url": params.issueUrl,
		}
	};

	if (params.authenticationType === JWT_LOGIN) {
		return (
			<div className={"authentication authenticate-prompt-jwt"}>
				<div className="authenticate-screen jwt" />
				<div className="authenticate jwt">
					<div className={params.popupType}>
						<h2 className="title">{displayText.loginTitleJWT}</h2>
						<div className="login-prompt">
							<button id={'login-link'} className={'button login-link'} onClick={() => loginLink({...authParams, label: displayText.loginLabelJWT})}>{displayText.loginLabelJWT}</button>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={"authentication authenticate-prompt"}>
				<div className="authenticate-screen" />
				<div className="authenticate">
					<div className={params.authView}>
						<h2 className="title">{displayText.promptTitle}</h2>
						<p className="copy">{displayText.promptCopy}</p>
						<button id={'subscribe-button'} className={'button'} onClick={() => subscribeButton({...authParams, label: displayText.promptLabel})}>{displayText.promptLabel}</button>
						<div className="login-prompt">
							<span>{displayText.promptLogin}</span>
							<button id={'login-link'} className={'button login-link'} onClick={() => loginLink({...authParams, label: displayText.loginLabel})}>{displayText.loginLabel}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

};
export {AuthenticatePrompt};
