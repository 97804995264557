import {getObjectFromJSON} from "./objectUtils";
import {isEmpty} from "./generalUtils";
import {clone} from "./objectUtils";


/**
 * Test two arrays to see if they have the same values.
 * Note that duplicate values in one array that are not duplicated
 * in the other array will return false.
 *
 * @param arr1 first array
 * @param arr2 second array
 * @returns {boolean} true: arrays have same values
 */
const arraysEqual = (arr1, arr2) => {
	let isEqual = true;
	if (typeof arr1 === 'string') {
		arr1 = getObjectFromJSON(arr1, null);
	}
	if (typeof arr2 === 'string') {
		arr2 = getObjectFromJSON(arr2, null);
	}
	if (Array.isArray(arr1) === false || Array.isArray(arr2) === false) {
		isEqual = false;
	} else if (arr1.length !== arr2.length) {
		isEqual = false;
	} else if (arr1.length > 0) {
		arr1.forEach((item) => {
			if (arr2.indexOf(item) === -1) {
				isEqual = false;
			}
		});
	}
	return isEqual;
};
export {arraysEqual};


/**
 * Remove duplicate entries from an array
 *
 * @param arr array
 * @param removeEmpty remove entries that define as empty
 * @returns {*} de-duped array
 */
const arrayRemoveDups = (arr, removeEmpty) => {
	removeEmpty = typeof removeEmpty === 'boolean' ? removeEmpty : false;
	let dedupedArray = clone(arr);

	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	dedupedArray = dedupedArray.filter( onlyUnique );
	if (removeEmpty) {
		dedupedArray = dedupedArray.filter(el => !isEmpty(el));
	}
	return dedupedArray;
};
export {arrayRemoveDups};


/**
 * Concat two arrays with no duplicates.
 * Note: standard concat just joins two arrays together, with duplicates if present.
 *
 * @param arr1 array 1
 * @param arr2 array 2
 * @returns {*} concatenated array
 */
const arraysConcatNoDup = (arr1, arr2) => {
	return arrayRemoveDups(arr1.concat(arr2));
};
export {arraysConcatNoDup};


/**
 * Create an array from a string with separator.
 * Then, go through each string value and trim.  If not a string, simply
 * keep the value.
 *
 * @param stringVal original string value
 * @param separator separator to crete array
 * @param params extra instructions
 *     removeDups: remove duplicate entries
 *     removeEmpty: if array value is empty, remove
 */
const arrayFromStringTrim = (stringVal, separator, params) => {
	params = Object.assign({
		removeDups: false,
		removeEmpty: false
	}, params);
	stringVal = typeof stringVal === 'string' ? stringVal : '';
	separator = typeof separator === 'string' ? separator : ',';

	// split string - create array
	let arr = stringVal.split(separator);
	// loop through array and trim each value if it is a string; otherwise just return value
	arr = arr.map((str) => {
		return typeof str === 'string' ? str.trim() : str;
	});
	if (params.removeDups) {
		arr = arrayRemoveDups(arr, params.removeEmpty);
	}
	return arr;
};
export {arrayFromStringTrim};


/**
 * Sort a list of objects by multiple attributes passed in as an array.
 * Pass the attributes in order by outer -> inner sort order.
 * In addition, for each attribute, you can specify whether that attributes is
 * to be sorted in normal or reverse order.
 *
 * SPECIAL CASE
 * You may pass in a single property as a string for sorting normally, and we will
 * try to handle it correctly.
 *
 * @param array list of objects
 * @param attributes array of sort attribute objects, where each object contains
 *     attribute: attribute to sort
 *     order: (optional) "normal" (default) or "reverse"
 *     SPECIAL CASE
 *         attributes: string for property to sort
 * @returns {*}
 */
const sortObjectsArray = (array, attributes) => {
	attributes = Array.isArray(attributes) ? attributes : (typeof attributes === 'string' ? [{attr: attributes}] : []);
	const sortArray = [].concat(array);  // create copy
	sortArray.sort(function(ar1, ar2) {
		let comparison = 0;
		attributes.forEach((attribute) => {
			const order = attribute.order === 'reverse' ? 'reverse' : 'normal';
			const attr = attribute.attr ? attribute.attr : '';
			let comparedValue = 0;
			if (ar1[attr] === ar2[attr]) {
				comparedValue = 0;
			} else if (order === 'reverse') {
				comparedValue = ar1[attr] < ar2[attr] ? 1 : -1;
			} else {
				comparedValue = (ar1[attr] > ar2[attr]) ? 1 : -1;
			}
			comparison = comparison || comparedValue;
		});
		return comparison;
	});
	return sortArray;
};
export {sortObjectsArray};


/**
 * Given an array of objects and a key property in an object, generate an object with
 * the key property as the key and each object as the value.
 * If key is not in array object, don't add to return object.
 *
 * ie.
 *     [
 *         {key: one, prop1: val1a, prop2: val2a},
 *         {key: two, prop1: val1b, prop2: val2b}
 *     ]
 * generates
 *     {
 *         one: {key: one, prop1: val1a, prop2: val2a}
 *         two: {key: two, prop1: val1b, prop2: val2b}
 *     }
 *
 * @param array
 * @param key
 * @returns {*}
 */
const createObjectFromArray = (array, key) => {
	const newObj = {};
	if (Array.isArray(array)) {
		array.forEach((item) => {
			if (item.hasOwnProperty(key)) {
				newObj[item[key]] = item;
			}
		});
	}
	return newObj;
};
export {createObjectFromArray};
